import { baseLighter, grayDark } from "../styles/colors";
export var BASE_INPUT = 'w-full z-1 border-0 resize-none focus:outline-none min-h-40 bg-transparent';

// Color classnames

export var BACKGROUND_COLOR_CLASSNAMES = {
  light: 'bg-white',
  'light-disabled': 'bg-gray-main',
  dark: 'bg-gray-main-dark-mode',
  'dark-disabled': 'bg-gray-dark-70',
  transparency: 'bg-gray-dark-8',
  'transparency-disabled': 'bg-gray-dark-70',
  'transparency-focused': 'bg-gray-dark-8'
};
export var BORDER_COLOR_CLASSNAMES = {
  light: 'border-gray-dark',
  'light-focused': 'border-primary-main',
  dark: 'border-transparent',
  'dark-focused': 'border-primary-lighter',
  transparency: 'border-base-lighter',
  'transparency-focused': 'border-white',
  error: 'border-red-main',
  disabled: 'border-transparent'
};
export var ALTERNATIVE_BORDER_COLOR_CLASSNAMES = {
  light: 'border-gray-light',
  'light-focused': 'border-primary-main',
  dark: 'border-transparent',
  'dark-focused': 'border-primary-lighter',
  transparency: 'border-base-lighter',
  'transparency-focused': 'border-white',
  error: 'border-red-main',
  disabled: 'border-transparent'
};
export var HINT_COLOR_CLASSNAMES = {
  light: 'text-gray-dark',
  'light-disabled': 'text-gray-dark',
  dark: 'text-base-lighter',
  'dark-disabled': 'text-gray-dark',
  transparency: 'text-base-lighter',
  'transparency-focused': 'text-base-lighter',
  'transparency-disabled': 'text-gray-dark'
};
export var INPUT_TEXT_COLOR_CLASSNAMES = {
  light: 'text-base-main caret-primary-main',
  'light-error': 'text-base-main caret-red-main',
  'light-disabled': 'text-gray-dark',
  dark: 'text-white caret-primary-lighter',
  'dark-error': 'text-white caret-red-main',
  'dark-disabled': 'text-gray-dark',
  transparency: 'text-white caret-primary-lighter',
  'transparency-error': 'text-white caret-red-main',
  'transparency-disabled': 'text-gray-dark'
};

// Hex colors

export var HINT_HEX_COLOR = {
  light: grayDark,
  'light-disabled': grayDark,
  dark: baseLighter,
  'dark-disabled': grayDark,
  transparency: baseLighter,
  'transparency-focused': baseLighter,
  'transparency-disabled': grayDark
};