import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * source1 (code): https://usehooks.com/page/2
 * source2 (types and ssr support): https://github.com/Andarist/use-onclickoutside/blob/master/src/index.ts
 */

import { useEffect } from 'react';
function useOnClickOutside(ref, handler, extraArrDependencies) {
  useEffect(function () {
    var listener = function listener(event) {
      // do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    if (typeof document !== 'undefined') {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    }
    return function () {
      if (typeof document !== 'undefined') {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      }
    };
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
  }, [ref, handler].concat(_toConsumableArray(extraArrDependencies || [])));
}
export default useOnClickOutside;